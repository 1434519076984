.formStep input, select {
    display: block;
    width: 100%;
    height: calc(1.5em + 1.3rem + 2px);
    padding: 0.65rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e2e5ec;
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

FormControlLabel {
    color: #1BC5BD;
}

.formStep label{
    display: inline-block;
    margin: .5rem 0;
    color: #212529;
    text-align: left;
}
.formStep span{
    color: #ff0000
}
.customerStep {
    padding: 15px;
}
.customerStep .next{
    margin-top: 35px;
}

.customerStep .previous{
    margin-top: 35px;
}
.customerStep h2{
    color: #212529;
    margin-bottom: 20px
}
.link_color_datatable{
    color: #696969;
}
.link_color_datatable:hover{
    color: #63191a;
    transition: 0.5s;
}

.hide{
    display: none;
}

.attrTitle{
    position: relative;
    display: flex;
    top: 10px; 
    margin: .5rem 0;
    color: #212529;
    text-align: left;
}

.horizontalLine{
    width: 99.5%;
    border: 1px solid #f0f0ee;
    border-radius: 5px;
}

.btnAdd{
    position: relative;
    float: right;
    margin-right: 0%;
    color: #ffffff;
    background-color: #1BC5BD;
    border-color: #1BC5BD;
    border: none;
    border-radius: 4px;    
}

.btnRemoveItem {
    display: block;
    float: right;
    padding: 0 5px 0 5px;
    background: #923434;
    border: 0;
    color: #fff;
    height: 20px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    margin-top: 8px;
}

.rowAttr{
    margin-bottom: 10px;
    padding-bottom: 10px;
    background-color: #f0f8ff;
    border-top: outset;
    border-width: 4px;
    border-radius: 10px;   
}

.rowAttr_in{
    -webkit-animation: rowAttr_in 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: rowAttr_in 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.rowAttr_out{
	-webkit-animation: rowAttr_out 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: rowAttr_out 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes rowAttr_in {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
}
@keyframes rowAttr_in {
    0% {
        -webkit-transform: scale(0.5);
                transform: scale(0.5);
    }
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

@-webkit-keyframes rowAttr_out {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
        opacity: 1;
    }
    100% {
      -webkit-transform: scale(0);
              transform: scale(0);
        opacity: 1;
    }
}
@keyframes rowAttr_out {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
        opacity: 1;
    }
    100% {
      -webkit-transform: scale(0);
              transform: scale(0);
        opacity: 1;
    }
}
