.topbar {
  background: #fff;
  padding: 20px 35px 20px 35px;
  z-index: -2;
  box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.08);
}
.btn-topbar {
  width: 35px;
  height: 35px;
  background: #fff;
  text-align: center;
  padding: 5px;
  margin: 0 2px;
  border-radius: 10px;
}
.btn-topbar:hover {
  background: #ffedee;
  cursor: pointer;
  transition: 0.5s;
}
.btn-topbar svg {
  width: 19px;
}
.welcome {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.image-user {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin-left: 10px !important;
  background-size: cover !important;
  cursor: pointer;
}

.image-user:hover {
  box-shadow: inset 0 0 0 10000px rgba(0, 0, 0, 0.35);
}

.image-user label {
  display: none;
  font-size: 8px;
  text-transform: uppercase;
  font-weight: 530;
  color: white;
  text-align: center;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.image-user:hover label {
  display: flex;
}

.dropdown-menu {
  min-width: 18rem;
  box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
  border: 0;
  cursor: default;
}
.dropdown-menu a {
  width: 100%;
  display: block;
}
.dropdown-menu.show {
  padding: 0px;
  margin-top: 40px;
  left: -150px !important;
}

.dropdown-dashboard button {
  width: 150px;
  background: #fff;
  color: #585858;
  border: 1px solid #a4a4a4;
}
.dropdown-dashboard .dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 6px !important;
  transform: translateY(-50%);
}
.dropdown-dashboard .dropdown-menu.show {
  padding: 0px;
  margin-top: 40px;
  left: 0px !important;
  top: -35px !important;
}

@media (max-width: 768px) {
  .dropdown-menu {
    min-width: 20rem;
  }
  .dropdown-menu.show {
    padding: 0px;
    margin-top: 40px;
    left: -50px !important;
  }
  .containerNotification {
    padding: 0px;
  }
}

.headerNotification {
  width: 100%;
  height: 5rem;
  background: #5f5f5f;
  color: #fff;
  padding: 20px;
  text-align: center;
  border-radius: 7px 7px 0 0;
}
.headerNotification h4 {
  font-size: 15px;
  line-height: 25px;
}

.containerNotification {
  padding: 10px;
}
.containerNotification p {
  font-size: 11px;
  line-height: 17px;
  padding-left: 10px;
}

.iconNotification {
  width: 35px;
  height: 35px;
  background: #fbe1e1;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  color: #5a1213;
  margin-right: 2;
}
.iconNotification:hover {
  width: 38px;
  height: 38px;
  transition: 0.5s;
}

.noNotifications {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.fade-in-top {
  -webkit-animation: fade-in-top 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-top 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-11-11 15:49:6
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in-top
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
