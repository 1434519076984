/*Estilo principal de toda a aplicação*/
html,
body {
  height: 100%;
  margin: 0px;
  padding: 0px;
  font-size: 13px;
  font-weight: 300;
  font-family: Poppins, Helvetica, sans-serif; /*Fonte padrão utilizada para todo o frontend*/
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  min-height: 625px;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {  

   opacity: 1;

}

label.important:after {
  content: ' *';
  color: red;
  font-weight: bold;
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 1100px !important;
  }
}

.homeLogin {
  min-height: 625px;
}

.errorMessages2 {
  color: #ffffff;
  border-bottom: 1px solid #ffffff;
  padding: 2px;
  display: flex;
  justify-content: center;
  font-size: 9px;
}
.container-fluido {
  min-height: 625px;
}

.container-fluido {
  background-image: url('/media/bg/bg-4.webp');
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 10;
  background-size: cover;
}
@media (max-width: 1366px) {
  .container-fluido {
    background-image: url('/media/bg/bg-4.webp');
    position: static;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 10;
    background-size: cover;
  }
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card-header {
  padding: 2rem 2.25rem;
  margin-bottom: 0;
  background-color: #ffffff;
  border-bottom: 1px solid #ebedf3;
}
.card-header small {
  font-size: 70%;
  font-weight: 400;
  color: #cecece;
}

.card-label {
  color: #000;
}
.card-body {
  padding: 2rem 2.25rem;
  color: #3f4254;
}
.card-title {
  color: #000;
}
.btn.btn-success {
  color: #ffffff;
  background-color: #1bc5bd;
  border-color: #1bc5bd;
}
.btn.btn-danger {
  color: #ffffff;
  background-color: #ee2d41;
  border-color: #ee2d41;
}
.btn.btn-primary {
  color: #ffffff;
  background-color: #3699ff;
  border-color: #3699ff;
}
.badge-danger {
  color: #fff;
  background-color: #ee2d41;
  padding: 5px 10px;
}
.badge-success {
  color: #fff;
  background-color: #1bc5bd;
  padding: 5px 15px;
}
.modal-header {
  padding: 1.5rem 1.75rem;
  border-bottom: 1px solid #ebedf3;
}
.modal-title {
  font-weight: 500;
  font-size: 1.3rem;
  color: #181c32;
}
.modal-content {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgb(0 0 0 / 0%);
  border-radius: 0.5rem;
}
.modal-body {
  padding: 1.75rem;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #1bc5bd;
  background-color: #1bc5bd;
}

.breadcrumbs {
  background: #fff;
}
.breadcrumbs a {
  color: #000;
  margin: 0 10px;
}
.breadcrumbs a:hover {
  color: #601617;
  transition: 0.5s;
}
.alert-primary {
  background: #e1f0ff;
  color: #3699ff;
  border: 0;
}
.custom-switch {
  padding-left: 0rem;
  display: flex;
  justify-content: center;
}
.alert-success {
  color: #1bc5bd;
  background-color: #defffd;
  border-color: #defffd;
}

/*Inicio Switch*/

.switch {
  position: relative;
  display: inline-block;
  height: 1rem;
  width: 1.75rem;
  pointer-events: all;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  border: #adb5bd solid 1px;
  -webkit-transition: 0.1s;
  transition: 0.1s;
}

.slider:before {
  position: absolute;
  content: '';
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  top: 1px;
  left: 0.8px;
  /*bottom: 4px;*/
  background-color: white;
  background-color: #adb5bd;
  -webkit-transition: 0.1s;
  transition: 0.1s;
}

input:checked + .slider {
  background-color: #1bc5bd;
  border: #1bc5bd solid 1px;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(10px);
  -ms-transform: translateX(10px);
  transform: translateX(10px);
  background-color: white;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
/*Fim Switch*/

.MUIDataTable-responsiveBase {
  overflow: visible !important;
}
