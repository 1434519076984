.filterAlerts{
  display:flex;
  align-items: center;
}

.filterAlerts a{
  color: #000
}

.filterAlerts input, select, textarea {
  height: calc(1.5em + 1.3rem + 2px);
  padding: 0.65rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e2e5ec;
  border-radius: 4px;
  margin-left: 3px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}