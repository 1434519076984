.mt_logo_sidebar {
  width: 75%;
  margin: 15px 0;
}

.fade-in {
  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
.slide-in-left {
  -webkit-animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
/*Keyframes para efeito no logotipo*/
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/*Keyframes para efeito no menu*/
@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

.p-4 {
  width: 100%;
}

#sidebar {
  min-width: 270px;
  max-width: 300px;
  background: url('/media/bg/bg-menu.webp');
  /*background: #651b1c;*/
  color: #fff;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  position: relative;
  display: flex;
  align-items: start;
  background-attachment: fixed;
}
#sidebar .h6 {
  color: #fff;
}
#sidebar.active {
  display: block;
}
#sidebar.disabled {
  display: none;
}
#sidebar h1 {
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 30px;
}
#sidebar h1 .logo {
  color: #fff;
}
#sidebar h1 .logo span {
  font-size: 14px;
  color: #44bef1;
  display: block;
}
#sidebar ul.components {
  padding: 0;
}
#sidebar ul li {
  font-size: 14px;
}
#sidebar ul li > ul {
  margin-left: 10px;
}
#sidebar ul li > ul li {
  font-size: 14px;
}
#sidebar ul li a {
  padding: 10px 0;
  display: block;
  color: rgb(255 255 255 / 75%);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
#sidebar ul li a span {
  color: #44bef1;
}
#sidebar ul li a:hover {
  color: #fff;
}
#sidebar ul li a.active {
  background: transparent;
  color: #fff;
}
@media (max-width: 991.98px) {
  #sidebar {
    margin-left: -270px;
  }
  #sidebar.active {
    margin-left: 0;
  }
  .navbar {
    align-items: flex-start !important;
  }
}
#sidebar .custom-menu {
  display: inline-block;
  position: absolute;
  top: 20px;
  right: 0;
  margin-right: -20px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
@media (prefers-reduced-motion: reduce) {
  #sidebar .custom-menu {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
}
#sidebar .custom-menu .btn {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  position: relative;
}
#sidebar .custom-menu .btn i {
  margin-right: -40px;
  font-size: 14px;
}
#sidebar .custom-menu .btn.btn-primary {
  background: transparent;
  border-color: transparent;
  z-index: 10;
}
#sidebar .custom-menu .btn.btn-primary:after {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: '';
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  background: #5c1010;
  border-radius: 10px;
}
#sidebar .custom-menu .btn.btn-primary:hover,
#sidebar .custom-menu .btn.btn-primary:focus {
  background: transparent !important;
  border-color: transparent !important;
}

a[data-toggle='collapse'] {
  position: relative;
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.chooseSystem {
  height: 40px;
}

@media (max-width: 991.98px) {
  #sidebarCollapse span {
    display: none;
  }
}
