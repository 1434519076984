body {
  font-family: 'Poppins', Arial, sans-serif;
  font-size: 14px;
  line-height: 1.8;
  font-weight: normal;
  background: #eef0f8;
  color: gray;
}

.wrapper {
  width: 100%;
}

.defaultCards{
 
}

#content {
  width: 100%;
  padding: 0;
  min-height: 100vh;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.content_all {
  padding: 2.5em;
}

.barChart {
  padding: 50px;
  margin: 0px 40px 40px 40px;
}

.doughnutChart {
  text-align: center;
  margin-bottom: 25px;
}

.icones-uf {
  display: flex;
  width: 40px;
  height: 40px;
  background: #792b2b;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 10px;
  margin: 0 auto;
}

.table-dashboard {
  text-align: center;
  width: 100%;
}

.height-limit {
  min-height: 355px;
}

.estados-dash .alert-secondary {
  color: #383d41;
  background-color: #f0f0f0;
  border-color: #ffffff;
}
.icon-contract-type-dash {
  width: 40px;
  height: 40px;
  background: #fff5f8;
  display: flex;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: #b41e1e;
}

.icon-whatsapp-dash {
  width: 40px;
  height: 40px;
  background: #e7fae2;
  display: flex;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: #189e48;
}

.icon-whatsapp-dash-negative {
  width: 40px;
  height: 40px;
  background: #fff3f3;
  display: flex;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: #7f1212;
}
.table-contract-type-dash td {
  border-top: 0 !important;
}

.table-contract-type-dash td span {
  width: 100%;
  height: 30px;
  background: #ececec;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  color: #6e6e6e;
  margin-top: 5px;
}

.card-mt-4 {
  margin-top: 15px;
}

@media (max-width: 768px) {
  .barChart {
    padding: 0;
    margin: 0;
  }
  .doughnutChart {
    margin-top: 40px;
  }
}
