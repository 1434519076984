.lineNotification :hover {
  cursor: pointer;
}

.newNotification {
  font-weight: bold;
}

.oldNotification {
  font-weight: normal;
}

@media (max-width: 768px) {
  .dropdown-menu {
    min-width: 20rem;
  }
  .dropdown-menu.show {
    padding: 0px;
    margin-top: 40px;
    left: -50px !important;
  }
  .containerNotification {
    padding: 0px;
  }
}

.headerNotification {
  width: 100%;
  height: 5rem;
  background: #5f5f5f;
  color: #fff;
  padding: 20px;
  text-align: center;
  border-radius: 7px 7px 0 0;
}
.headerNotification h4 {
  font-size: 15px;
  line-height: 25px;
}

.containerNotification {
  padding: 10px;
}
.containerNotification p {
  font-size: 11px;
  line-height: 17px;
  padding-left: 10px;
}

.iconNotification {
  width: 35px;
  height: 35px;
  background: #fbe1e1;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  color: #5a1213;
  margin-right: 2;
}
.iconNotification:hover {
  width: 38px;
  height: 38px;
  transition: 0.5s;
}

.iconNotification2 {
  width: 35px;
  height: 35px;
  background: #fbe1e1;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  color: #5a1213;
  margin-right: 2;
}
.iconNotification2:hover {
  width: 38px;
  height: 38px;
  transition: 0.5s;
}

.noNotifications {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.fade-in-top {
  -webkit-animation: fade-in-top 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-top 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
@media (max-width: 768px) {
  .dropdown-menu {
    min-width: 20rem;
  }
  .dropdown-menu.show {
    padding: 0px;
    margin-top: 40px;
    left: -50px !important;
  }
  .containerNotification {
    padding: 0px;
  }
}

.headerNotification {
  width: 100%;
  height: 5rem;
  background: #5f5f5f;
  color: #fff;
  padding: 20px;
  text-align: center;
  border-radius: 7px 7px 0 0;
}
.headerNotification h4 {
  font-size: 15px;
  line-height: 25px;
}

.containerNotification {
  padding: 10px;
}
.containerNotification p {
  font-size: 11px;
  line-height: 17px;
  padding-left: 10px;
}

.iconNotification {
  width: 35px;
  height: 35px;
  background: #fbe1e1;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  color: #5a1213;
  margin-right: 2;
}
.iconNotification:hover {
  width: 38px;
  height: 38px;
  transition: 0.5s;
}

.noNotifications {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.fade-in-top {
  -webkit-animation: fade-in-top 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-top 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
