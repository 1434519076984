.fa-whatsapp {
  color: green;
}

.fa-envelope {
  color: #ff331d;
}

.fa-times {
  color: red;
}

.fa-check {
  color: green;
}
