.formStep input, select {
    display: block;
    width: 100%;
    height: calc(1.5em + 1.3rem + 2px);
    padding: 0.65rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e2e5ec;
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.formStep label{
    display: inline-block;
    margin: .5rem 0;
    color: #212529;
    text-align: left;
}
.formStep span{
    color: #ff0000
}
.customerStep .next{
    margin-top: 35px;
}

.customerStep .previous{
    margin-top: 35px;
}
.customerStep h2{
    color: #212529;
    margin-bottom: 20px
}
.link_color_datatable{
    color: #696969;
}
.link_color_datatable:hover{
    color: #63191a;
    transition: 0.5s;
}