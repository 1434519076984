
.btnPermissionCustom{
    padding: 12px;
    display:flex;
    justify-content: space-between;
}
.optionPermission{
    padding: 5px 0 15px 15px;
}
.titleMenu {
    background: #d7fffd;
    color: #1bc5bd;
    border-radius: 0;
    border-left: 3px solid #1bc5bd;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.lineRow{
    margin: 0
}
.titleMenu h4{
    font-size: 12px;
    margin:0
}
.activeScheme{
    display: flex;
    justify-content: space-between;
    border-radius: 0;
    border-left: 3px solid #3699ff;
    margin: 0 0 8px 0
}
.btnAddModule{
    display:flex;
    padding: 20px 25%;
}
.custom-switch {
    padding-left: 1.25rem !important;
}