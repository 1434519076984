#page-map {
  width: 100%;
  height: 100vh;
}

form.landing-page-form {
  width: 500px;
  background: #ffffff;
  border: 1px solid #d3e2e5;
  border-radius: 30px;
  padding: 20px 40px;
  position: absolute;
  top: 30px;
  left: 70px;
  z-index: 1;
}

@media (max-width: 768px) {
  form.landing-page-form {
    width: 90%;
    top: 0px;
    left: 18px;
  }
}

#page-map .leaflet-container {
  z-index: 0;
  border-radius: 20px;
}

form.landing-page-form fieldset {
  border: 0;
}

form.landing-page-form fieldset legend {
  width: 100%;

  font-size: 32px;
  line-height: 34px;
  color: #5c8599;
  font-weight: 700;

  border-bottom: 1px solid #d3e2e5;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

form.landing-page-form .input-block + .input-block {
  margin-top: 24px;
}

form.landing-page-form .input-block label {
  display: flex;
  color: #8fa7b3;
  margin-bottom: 8px;
  line-height: 24px;
}

form.landing-page-form .input-block label span {
  font-size: 14px;
  color: #8fa7b3;
  margin-left: 24px;
  line-height: 24px;
}

form.landing-page-form .input-block input {
  width: 100%;
  background: #f5f8fa;
  border: 1px solid #d3e2e5;
  border-radius: 20px;
  outline: none;
  color: #5c8599;
}

form.landing-page-form .input-block input {
  height: 44px;
  padding: 0 16px;
}

form.landing-page-form button.confirm-button {
  margin-top: 34px;

  width: 100%;
  height: 64px;
  border: 0;
  cursor: pointer;
  background-color: #752525;
  border-radius: 20px;
  color: #ffffff;
  font-weight: 800;

  transition: background-color 0.2s;
}

form.landing-page-form button.confirm-button:hover {
  background-color: #7b7b7b;
}

/* Pop Up - Marker */

#page-map .map-popup .leaflet-popup-content-wrapper {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 20px;
  box-shadow: none;
  font-family: 'Poppins';
}

#page-map .map-popup .leaflet-popup-content h3 {
  color: #752525;
  font-size: 17px;
  font-weight: bold;
  margin: 8px 12px;
}

.btn-map {
  border-radius: 100px;
  background: #752525 !important;
  border: 0;
}

#page-map .map-popup .leaflet-popup-content p {
  color: #042f38;
  font-size: 12px;
  margin: 8px 12px;
  line-height: 15px;
  margin-bottom: 20px;
}

#page-map .map-popup .leaflet-popup-tip-container {
  display: none;
}

/* Styling react select */

.filter__control {
  border-radius: 20px !important;

  width: 100% !important;
  background: #f5f8fa !important;
  border: 1px solid #d3e2e5 !important;
  border-radius: 20px !important;
  outline: none !important;
  color: #5c8599 !important;
  margin-bottom: 15px;
}

.filter__option {
  background: #f5f8fa !important;
  color: #5c8599 !important;
}

.filter__option--is-focused {
  background: #d3e2e5 !important;
  color: #010101 !important;
}

.filterMonitoring__control {
  border-radius: 20px !important;

  width: 100% !important;
  background: #f5f8fa !important;
  border: 1px solid #d3e2e5 !important;
  border-radius: 20px !important;
  outline: none !important;
  color: #5c8599 !important;
  margin: 0 5px 15px 0;
}

.filterMonitoring__option {
  background: #f5f8fa !important;
  color: #5c8599 !important;
}

.filterMonitoring__option--is-focused {
  background: #d3e2e5 !important;
  color: #010101 !important;
}

.filterMonit > .css-2b097c-container {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  margin-right: 10px;
}

/*Detalhes*/
.box-equipments {
  width: 100%;
  height: 433px;
  border-radius: 15px;
}
.box-equipments-inner {
  width: 100%;
  overflow-y: scroll;
  margin-top: 7px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  height: 100%;
}
.box-equipments-inner::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #fff;
  height: 100px;
}

.box-equipments-inner::-webkit-scrollbar {
  width: 5px;
  background-color: #fff;
  height: 20px;
}

.box-equipments-inner::-webkit-scrollbar-thumb {
  border-radius: 20px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #acacac;
}

.box-equipments ul {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.box-equipments ul li {
  width: 100%;
  height: 64px;
  background: #dfdfdf;
  border-radius: 20px;
  color: #444444;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: 500ms;
  margin-bottom: 7px;
  margin-right: 15px;
}
.box-equipments ul li:hover {
  background: #c7c7c7;
}

.box-equipments-info {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.box-equipment-data {
  width: 100%;
}
.box-equipment-data img {
  width: 20%;
  border-radius: 100%;
}

.title-device {
  margin-top: 15px;
}

.box-equipments .box-equipments-info span {
  font-weight: bold;
  margin-left: 10px;
}

.box-equipments .box-equipments-button {
  padding-right: 20px;
}

.title-equipments {
  font-size: 24px;
  padding-bottom: 15px;
  color: #525151;
}
.title-equipments a {
  color: #525151;
}
.title-equipments a:hover {
  color: #979797;
  transition: 300ms;
}
.title-equipments img {
  margin: 0 5px 0 5px;
}

.load-more {
  background: #fff !important;
}

.loadMore-maps {
  border-radius: 100px;
  padding: 10px 25px;
  background: #752525 !important;
  border: 0;
}

.loadMore-maps:hover {
  background: #4e0e0e !important;
}

.modal-xl {
  max-width: 95% !important;
}
