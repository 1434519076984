.header-card{
    display:flex;
    justify-content: space-between;
}
.data-table-extensions > .data-table-extensions-filter > .icon{
    background-image: url('/media/search/search-icon.svg') !important;
    background-size: contain;

}
.MuiPaper-elevation4 {
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 0%), 0px 4px 5px 0px rgb(0 0 0 / 0%), 0px 1px 10px 0px rgb(0 0 0 / 0%) !important;
}
.MuiSvgIcon-root{
    color: #772a2b !important;
}