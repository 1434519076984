
.errorMessages{
    color: #ff0000;
    border-bottom: 1px solid #ff0000;
    padding: 2px;
    display: flex;
    justify-content: center;
    font-size: 9px
}

.formStep input, select {
    display: block;
    width: 100%;
    height: calc(1.5em + 1.3rem + 2px);
    padding: 0.65rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e2e5ec;
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.formStep label{
    display: inline-block;
    margin: .5rem 0;
    color: #212529;
    text-align: left;
}
.formStep span{
    color: #ff0000
}
.customerStep .next{
    margin-top: 35px;
}

.customerStep .previous{
    margin-top: 35px;
}
.customerStep h2{
    color: #212529;
    margin-bottom: 20px
}
.link_color_datatable{
    color: #696969;
}
.link_color_datatable:hover{
    color: #63191a;
    transition: 0.5s;
}
.btn-remove-item {
    display: block;
    padding: 0 5px 0 5px;
    background: #923434;
    border: 0;
    color: #fff;
    height: 20px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    margin-top: 8px;
}
.labelForm{
    display:flex;
    justify-content: space-between;
}
.errorForm{
    border: 1px solid #ff0000 !important;
    background: #fff8f8 !important;
}
.errorFormLabel{
    color: #ff0000
}

/* Logo Cliente */

.customer-logo{
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin-left: 10px !important;
    background-size: cover !important;
    cursor: pointer;
}

.customer-logo:hover{
    box-shadow: inset 0 0 0 10000px rgba(0, 0, 0, 0.35);
}

.customer-logo label {
    display: none;
    font-size: 8px;
    text-transform: uppercase;
    font-weight: 530;
    color: white;
    text-align: center;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.customer-logo:hover label {
    display: flex;
}
