#figure {
  cursor: pointer;
}

#fig {
  width: 20px;
}

.models-logo {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin-left: 10px !important;
  background-size: cover !important;
  cursor: pointer;
}

.models-logo:hover {
  box-shadow: inset 0 0 0 10000px rgba(0, 0, 0, 0.35);
}

.models-logo label {
  display: none;
  font-size: 8px;
  text-transform: uppercase;
  font-weight: 530;
  color: white;
  text-align: center;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.models-logo:hover label {
  display: flex;
}

.logo-img {
  margin: auto;
  width: 50%;
}
