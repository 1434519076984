

@keyframes hide {
  from { opacity: 1 }
  to   { opacity: 0 }
}

.alert-box {
  animation: hide 2s 2s forwards;
  padding: 15px;
  border: 0;
  border-radius: 60px;
  text-align: center;
  margin-top: 15px;
  margin-right: 10px;
}

.success {
  color: #3c763d;
  background-color: #dff0d898;
}