.animation {
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
}
